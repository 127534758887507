$color-shadow: hsl(240, 4%, 10%);
$color-shadowStrong: hsl(240, 4%, 5%);
$color-white: hsl(240, 4%, 15%);
$color-whiteStrong: hsl(240, 4%, 20%);
$color-pink: rgb(255, 85, 124);
$color-red: #ae1100;
$ruler: 16px;

.project-container {
  max-width: 80%;
  margin: 80px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  h1 {
    font-size: 3rem;
    margin-top: 5rem;
  }

  p,
  img {
    max-width: 80%;
    line-height: 2rem;
    font-size: 1.2rem;
  }

  a {
    text-decoration: none;
    color: $color-pink;
  }

  .button-group {
    display: flex;
    gap: 1rem;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    transition: all 0.1s ease-in-out;
    border-radius: 10px;
    cursor: pointer;
    text-shadow: none;
    padding: 10px;
    background: $color-pink;
    color: azure;

    &:hover {
      background: #af1958;

    }

    &:active {
      box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
    }
  }

  .cover-image {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tech-stack {
    display: flex;
    gap: 1rem;
  }

  .skills-infos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .skill-group {
      display: flex;
      flex-direction: column;
      align-items: center;
      .skill {
        background: rgb(11, 11, 11);
        background: linear-gradient(315deg, $color-shadow 0%, $color-white 100%);
        width: 100px;
        height: 100px;
        margin: 20px;
        box-sizing: border-box;
        box-shadow: -4px -4px 13px $color-whiteStrong, 4px 4px 13px $color-shadowStrong;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: all .2s;

        &:hover {
          box-shadow: -6px -6px 13px $color-whiteStrong, 6px 6px 13px $color-shadowStrong;
          transform: scale(1.05);
        }
      }

      .skill-info {
        color: rgb(204, 204, 204);
        text-align: center;
      }
    }
  }
}